import * as client_hooks from '../../src/hooks.client.js';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')];

export const server_loads = [];

export const dictionary = {
	"/": [5],
	"/admin": [6,[2]],
	"/admin/articles": [7,[2]],
	"/admin/articles/create-blog": [9,[2]],
	"/admin/articles/[slug]": [8,[2]],
	"/admin/calculator": [10,[2]],
	"/admin/coupons": [11,[2]],
	"/admin/invoices": [12,[2]],
	"/admin/notes": [13,[2]],
	"/admin/offers": [14,[2]],
	"/admin/offers/new": [16,[2]],
	"/admin/offers/[id]": [15,[2]],
	"/admin/orders": [17,[2]],
	"/admin/orders/[id]": [18,[2]],
	"/admin/properties": [19,[2]],
	"/admin/properties/new": [21,[2]],
	"/admin/properties/[slug]": [20,[2]],
	"/admin/users": [22,[2]],
	"/admin/users/new": [24,[2]],
	"/admin/users/[id]": [23,[2]],
	"/b2b": [25],
	"/buying-process": [26],
	"/communitychronicle": [27,[3]],
	"/communitychronicle/category/[category_name]": [29,[3]],
	"/communitychronicle/[slug]": [28,[3]],
	"/faqs": [30],
	"/login": [31],
	"/lost-password": [32],
	"/me": [33,[4]],
	"/me/account-details": [34,[4]],
	"/me/invoices": [35,[4]],
	"/me/my-favorites": [36,[4]],
	"/me/offers": [37,[4]],
	"/me/offers/[id]": [38,[4]],
	"/me/orders": [39,[4]],
	"/me/orders/[id]": [40,[4]],
	"/me/payment-methods": [41,[4]],
	"/our-purpose": [42],
	"/our-story": [43],
	"/privacy": [44],
	"/properties": [45],
	"/properties/in-contract": [49],
	"/properties/sold": [50],
	"/properties/[slug]": [46],
	"/properties/[slug]/checkout": [47],
	"/properties/[slug]/checkout/success": [48],
	"/register": [51],
	"/reset": [52],
	"/sell": [53],
	"/terms": [54],
	"/verify": [55]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};