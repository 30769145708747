import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://5182af59b1f24ea49ba8ea1c108ad7ca@o4504458343415808.ingest.sentry.io/4504480978042885',
  tracesSampleRate: parseFloat(import.meta.env.VITE_TRACES_SAMPLE_RATE),

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: parseFloat(import.meta.env.VITE_REPLAY_SAMPLE_RATE),

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_ERROR_REPLAY_SAMPLE_RATE),
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [new Replay({
    networkDetailAllowUrls: [/.*/],
    networkRequestHeaders: ['X-Custom-Header'],
    networkResponseHeaders: ['X-Custom-Header'],
  })],
  environment: import.meta.env.VITE_ENVIRONMENT
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
